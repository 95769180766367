@import "core";
.section.header {
	.header-search {
		position: relative;
		margin-left: 2.1rem;
		color: $color-text;
		width: 30.9rem;

		.search-icon {
			font-size: 1.2rem;
			position: absolute;
			pointer-events: none;
			left: 1.7rem;
			top: 50%;
			transform: translate3d(0, -50%, 0);
		}

		.search-input {
			width: 100%;
			display: block;
			background-color: $color-white;
			border-radius: $radius-small;
			color: inherit;
			font-size: 1.4rem;
			line-height: 4rem;
			height: 4rem;
			border: none;
			padding: 0 1rem 0 3.8rem;
		}
	}
}